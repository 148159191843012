import revive_payload_client_Bq8cRNoGxv from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.9.1_rollup@4.9.4_sass@1.69.7_vite@5.0.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Qro7sGWIMH from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.9.1_rollup@4.9.4_sass@1.69.7_vite@5.0.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_5ZsG8hM4qD from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.9.1_rollup@4.9.4_sass@1.69.7_vite@5.0.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_RqwWqE65aT from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/nuxt-site-config@2.2.8_@nuxt+devtools@1.0.8_@vue+compiler-core@3.4.6_nuxt@3.9.1_postcss@8.4.3_5zwgh4tn56xgudepr2tji7e7d4/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_Wfqoi0jr1i from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.9.1_rollup@4.9.4_sass@1.69.7_vite@5.0.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_XRE4SPmrpF from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.9.1_rollup@4.9.4_sass@1.69.7_vite@5.0.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_kfKdBHhSCs from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+@pinia+nuxt@0.5.1_rollup@4.9.4_vue@3.4.6/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/jenkins_home/workspace/picmastudio-web-2/.nuxt/components.plugin.mjs";
import prefetch_client_ymSbLAk38t from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.9.1_rollup@4.9.4_sass@1.69.7_vite@5.0.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_9Qv9mgXRqB from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/nuxt-gtag@1.1.2_rollup@4.9.4/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_ElB04wHJfg from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+@nuxtjs+device@3.1.1_rollup@4.9.4/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_JhgmsZtwxB from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+dayjs-nuxt@2.1.9_rollup@4.9.4/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import composition_fFKKcUt7F3 from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+@nuxtjs+i18n@8.0.0_rollup@4.9.4_vue-router@4.2.5_vue@3.4.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_kc8JN17FYI from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+@nuxtjs+i18n@8.0.0_rollup@4.9.4_vue-router@4.2.5_vue@3.4.6/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_lTXyF7uWgL from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/registry.npmmirror.com+nuxt@3.9.1_rollup@4.9.4_sass@1.69.7_vite@5.0.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_UDziRNETgC from "/var/jenkins_home/workspace/picmastudio-web-2/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_pinia@2.1.7_rollup@4.9.4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import Vue3Marquee_client_r0z0zv1iLW from "/var/jenkins_home/workspace/picmastudio-web-2/plugins/Vue3Marquee.client.ts";
import ffmpeg_client_UR7MRRMoZh from "/var/jenkins_home/workspace/picmastudio-web-2/plugins/ffmpeg.client.ts";
import firebase_client_zXNDa0wxFH from "/var/jenkins_home/workspace/picmastudio-web-2/plugins/firebase.client.ts";
import jsencrypt_client_8d3ih5ALte from "/var/jenkins_home/workspace/picmastudio-web-2/plugins/jsencrypt.client.ts";
import observe_visibility_client_tgSwJuLL3C from "/var/jenkins_home/workspace/picmastudio-web-2/plugins/observe-visibility.client.ts";
import router_scrollBehaviour_2Yeb7Kftyp from "/var/jenkins_home/workspace/picmastudio-web-2/plugins/router.scrollBehaviour.js";
export default [
  revive_payload_client_Bq8cRNoGxv,
  unhead_Qro7sGWIMH,
  router_5ZsG8hM4qD,
  _0_siteConfig_RqwWqE65aT,
  payload_client_Wfqoi0jr1i,
  check_outdated_build_client_XRE4SPmrpF,
  plugin_vue3_kfKdBHhSCs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ymSbLAk38t,
  plugin_client_9Qv9mgXRqB,
  plugin_ElB04wHJfg,
  plugin_JhgmsZtwxB,
  composition_fFKKcUt7F3,
  i18n_kc8JN17FYI,
  chunk_reload_client_lTXyF7uWgL,
  plugin_UDziRNETgC,
  Vue3Marquee_client_r0z0zv1iLW,
  ffmpeg_client_UR7MRRMoZh,
  firebase_client_zXNDa0wxFH,
  jsencrypt_client_8d3ih5ALte,
  observe_visibility_client_tgSwJuLL3C,
  router_scrollBehaviour_2Yeb7Kftyp
]