/**
 * 判断是否需要301跳转，若需要则跳转
 */
export default defineNuxtRouteMiddleware(async (to, from) => {
  const localePath = useLocalePath();
  // console.log("defineNuxtRouteMiddleware", to);
  if (to?.name?.includes("picma_web-enhance___")) {
    return navigateTo(
      localePath({
        name: "picma_web-funcType",
        params: { funcType: "enhance_pro" },
      }),
      { external: true, redirectCode: 301 }
    );
  } else if (to?.name?.includes("picma_web-funcType___")) {
    if (to.params) {
      // const type = (to.params.funcType as String).toLocaleLowerCase();
      let newFunc = "";
      switch (to.params.funcType) {
        case "AI_Eraser":
          newFunc = "object_remover";
          break;
        case "Landscape_AI":
        case "PNG_HD":
        case "WildFocus_AI":
          newFunc = "enhance_pro";
          break;
        case "Color_Master":
          newFunc = "color_master";
          break;
          case "Word_HD":
          newFunc = "word_hd";
          break;
      }
      if(newFunc){
        return navigateTo(
          localePath({
            name: "picma_web-funcType",
            params: { funcType: newFunc },
          }),
          { external: true, redirectCode: 301 }
        );
      }
    }
  } else if (to?.fullPath.includes("/jp")) {
    return navigateTo(to?.fullPath.replace("/jp", "/ja"), {
      external: true,
      redirectCode: 301,
    });
  } else if (to.fullPath.toLocaleLowerCase().includes("/zh_hant")) {
    return navigateTo(to?.fullPath.replace("/zh_hant", "/zh-hant"), {
      external: true,
      redirectCode: 301,
    });
  }

});
